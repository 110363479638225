import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {
  render() {
    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey="why">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="why">
                        <i className="las la-arrow-right"></i> Why Monalisa B.ed
                        college
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mission">
                        <i className="las la-arrow-right"></i> Our Mission
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="vision">
                        <i className="las la-arrow-right"></i> Our Vision
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="ranking">
                        <i className="las la-arrow-right"></i> Our Ranking
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                                            <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                        </Nav.Item> */}
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="why">
                      <h4 className="tab-title">Why Monalisa Institute of Medical Science
                      </h4>
                      <p className="tab-desc">
                        exceptional faculty members who bring years of expertise
                        and passion to the classroom. With a commitment to
                        academic excellence, they go above and beyond to ensure
                        that each student receives personalized attention and
                        support tailored to their unique goals and aspirations.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Exceptional faculty
                          dedicated to personalized support for student success.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Nurturing environment
                          fostering student empowerment and happiness as top
                          priority.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Academic excellence
                          guided by passionate mentors, unlocking students' full
                          potential.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mission">
                      <h4 className="tab-title">Our Mission</h4>
                      <p className="tab-desc">
                        At our college, our mission is to cultivate aspiring
                        educators who are not only proficient in their subject
                        matter but also equipped with the necessary pedagogical
                        skills and passion for teaching. We are committed to
                        fostering a dynamic learning environment that promotes
                        critical thinking, creativity, and innovation in
                        educational practices. Our goal is to empower our
                        graduates to become compassionate and effective teachers
                        who can inspire and motivate students to reach their
                        full potential, contribute to their communities, and
                        become lifelong learners.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Promote excellence in
                          teaching by providing rigorous academic training and
                          practical experiences.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Foster a supportive and
                          inclusive learning environment that nurtures the
                          growth and development of every student.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Empower graduates to
                          make meaningful contributions to the field of
                          education through innovative teaching methodologies
                          and a commitment to lifelong learning.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="vision">
                      <h4 className="tab-title">Our Vision</h4>
                      <p className="tab-desc">
                        Our vision at the college is to be a beacon of
                        excellence in teacher education, recognized for our
                        innovative approaches, transformative learning
                        experiences, and impactful contributions to the field of
                        education. We aspire to nurture a community of educators
                        who are forward-thinking, adaptable, and dedicated to
                        promoting equity, diversity, and inclusion in education.
                        Through our commitment to continuous improvement and
                        collaboration with stakeholders, we aim to shape the
                        future of education by producing compassionate and
                        competent teachers who can inspire positive change in
                        society.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>
                          Empower educators to be agents of change, driving
                          social impact and educational equity.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Strive to be a leader
                          in teacher education through innovative practices and
                          research.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Cultivate a diverse and
                          inclusive community that values collaboration,
                          respect, and empathy.
                        </li>
                      </ul>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">Our Ranking</h4>
                      <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                      </ul>
                    </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="research">
                      <h4 className="tab-title">Our Research</h4>
                      <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                      </ul>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default TabBox;
