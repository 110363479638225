import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ModalImage from "react-modal-image";
import { Styles } from "./styles/campusTour.js";

class CampusTour extends Component {
  state = {
    secTitle: "We Have Best Campus In The Region. Let's Explore The Campus.",
  };

  render() {
    return (
      <Styles>
        {/* Campus Tour */}
        <section className="campus-tour">
          <Container>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>{this.state.secTitle}</h4>
                </div>
              </Col>
              <Col lg="2.3" md="2">
                <div className="tour-box">
                  <ModalImage
                    small={
                      "/assets/images/BUILDING_01.jpeg"
                    }
                    large={
                      "/assets/images/BUILDING_01.jpeg"
                    }
                    alt=""
                  />
                </div>
                <div className="tour-box">
                  <ModalImage
                    small={
                      "/assets/images/BUILDING_03.jpeg"
                    }
                    large={
                      "/assets/images/BUILDING_03.jpeg"
                    }
                    alt=""
                  />
                </div>
              </Col>
              <Col lg="4" md="6">
                <Row>
                  <Col lg="6" md="6">
                    <div className="tour-box">
                      <ModalImage
                        small={
                          "/assets/images/sweet.jpeg"
                        }
                        large={
                          "/assets/images/sweet.jpeg"
                        }
                        alt=""
                        style={{ height: "160px" }}
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6">
                    <div className="tour-box">
                      <ModalImage
                        small={
                          
                          "/assets/images/BUILDING_03.jpeg"
                        }
                        large={
                          
                          "/assets/images/BUILDING_03.jpeg"
                        }
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="12" h="10">
                    <div className="tour-box" style={{ height: "160px" }}>
                      <ModalImage
                        small={
                          
                          "/assets/images/BUILDING_02.jpeg"
                        }
                        large={
                          
                          "/assets/images/BUILDING_02.jpeg"
                        }
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="2" md="3">
                <div className="tour-box">
                  <ModalImage
                    small={"/assets/images/flag.jpg"}
                    large={"/assets/images/flag.jpg"}
                    alt=""
                    
                  />
                </div>
              </Col>
              <Col lg="4" md="12">
                <Row>
                  <Col lg="12" md="6">
                    <div className="tour-box" style={{ height: "160px" }}>
                      <ModalImage
                        small={
                          "/assets/images/prize.jpeg"
                        }
                        large={
                          "/assets/images/prize.jpeg"
                        }
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="3">
                    <div className="tour-box">
                      <ModalImage
                        small={
                          "/assets/images/yoga.jpeg"
                        }
                        large={
                          "/assets/images/yoga.jpeg"
                        }
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="3">
                    <div className="tour-box">
                      <ModalImage
                        small={
                          "/assets/images/jyn.jpeg"
                        }
                        large={
                          "/assets/images/jyn.jpeg"
                        }
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default CampusTour;
