import React, { useEffect } from "react";
import Datas from "../data/footer/footer2.json";
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerTwo.js";

function FooterTwo() {

  return (
    <Styles>
      {/* Footer Two */}
      <footer
        className="footer2"
     
      >
        <Container>
          <Row>
              <Col>
              <div className="footer-logo-info">
                <div style={{ display: "flex" }}>
                <img
                  src={"https://res.cloudinary.com/da5mimn3m/image/upload/v1712737763/mlc/Monalisa%20D.pharma%20College/WhatsApp_Image_2024-04-07_at_09.25.12_o4jz3m.jpg"}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    marginRight: "10px",
                  }}
                  className="img-fluid"
                />
                <h2 style={{
                  color: "white"
                }}>
                MONALISA INSTITUTE OF MEDICAL SCIENCE
                </h2>
                </div>
                <p>
                  We Have Experienced Professionals & We Do Our Best To Achieve
                  Your Goal. Your Happiness Is Our First Priority.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="las la-map-marker"></i>
                    VILL + PO - KASHIMPUR,
                    DUTTAPUKUR DIST - NORTH 24 PARGANAS, PIN - 743248
                  </li>
                  <li>
                    <i className="las la-envelope"></i>
                    <a href="mailto:monalisacollegeofpharmacy@gmail.com">
                    monalisacollegeofpharmacy@gmail.com
                    </a>
                     / <a href="mailto:principalmims126@gmail.com">
                    principalmims126@gmail.com
                     </a>
                  </li>
                  <li>
                    <i className="las la-phone"></i>
                    <a href="tel:9193741234">9193741234</a>
                     / <a href="tel:9804588881">9804588881</a>
                  </li>
                </ul>
              </div>
              </Col>
          </Row>
          <Row>
          <Col md="12">
              <div className="copytext-area text-center">
                <p>
                  Copyright &copy; {
                    new Date().getFullYear()
                  } | Designed With{" "}
                  <i className="las la-heart"></i> by{" "}
                  <a
                    href={"https://www.myleadingcampus.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MyLeading Campus
                  </a>
                </p>
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a href={ "/"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ "/"}>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={ "/"}>
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Back To Top  */}
        <BackToTop />
      </footer>
    </Styles>
  );
}

export default FooterTwo;
