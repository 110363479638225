import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// import { Dropdown } from "react-bootstrap";
import Search from './common/Search';
// import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";

class HeaderTwo extends Component {
  render() {
    return (
      <Styles>
        {/* Topbar 2 */}
        <section className="top-bar2">
          <Container>
            <Row>
              <Col lg="12">
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <i className="las la-phone"></i>
                      <a href="tel:9193741234">9193741234</a>/<a href="tel:9804588881">9804588881</a>
                    </li>
                    <li className="list-inline-item">
                      <i className="las la-envelope"></i>
                      <a href="mailto:monalisacollegeofpharmacy@gmail.com">
                      monalisacollegeofpharmacy@gmail.com
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <i className="las la-map-marker"></i> Kolkata, PIN - 743248.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Row style={{
            maxWidth: "90%",
            margin: "auto",
          }}>
            <Col md="7">
              <div className="logo" style={{
                display: "flex",
                alignItems: "center",
              }}>
                <Link to={"/"} style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}>
                  <img
                    src={
                      "https://res.cloudinary.com/da5mimn3m/image/upload/v1712991636/mlc/Monalisa%20D.pharma%20College/WhatsApp_Image_2024-04-07_at_09.25.12_o4jz3m.jpg"
                    }
                    alt=""
                    style={{
                      width: "120px",
                      height: "120px",
                    }}
                  />
                  <div>
                    <p style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                      letterSpacing: "0px",
                    }}>
                      Monalisa Institute of Medical Science
                    </p>
                    <p style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                    }}>
                      Recognized By PCI, Govt. of India
                    </p>
                    <p style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                    }}>
                      Affiliated to WBSCTVESD, Govt. of West Bengal
                    </p>
                  </div>
                </Link>
              </div>
            </Col>
            <Col md="5">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item dropdown active">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Home
                      {/* <i className="las la-angle-down"></i> */}
                    </Link>
                    {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={ "/"}>Home Style 1</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={ "/home-two"}>Home Style 2</Link></li>
                                            </ul> */}
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Pages <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/about"}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/gallery"}
                        >
                          Gallery
                        </Link>
                      </li>
                      {/* <li className="nav-item"><Link className="nav-link" to={ "/login"}>Log In</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={ "/registration"}>Registration</Link></li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/contact"}
                        >
                          Contact
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/faq"}
                        >
                          Faq
                        </Link>
                      </li>
                      {/* <li className="nav-item"><Link className="nav-link" to={ "/404"}>404</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={ "/coming-soon"}>Coming Soon</Link></li> */}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Courses <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      {/* <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/course-grid"}
                              >
                                Course Grid
                              </Link>
                            </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/course-list"}
                        >
                          Course List
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/course-details"}
                              >
                                Course Details
                              </Link>
                            </li> */}
                    </ul>
                  </li>
                  {/* <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={ "/"}
                            data-toggle="dropdown"
                          >
                            Event <i className="las la-angle-down"></i>
                          </Link>
                          <ul className="dropdown list-unstyled">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to={ "/events"}
                              >
                                Events
                              </Link>
                            </li>
                            <li className="nav-item">
                             
                            </li>
                          </ul>
                        </li> */}
                </ul>
                {/* <div className="search-box">
                        <Search />
                      </div> */}
                <div className="apply-btn">
                  <Link to={"/registration"}>
                    <i className="las la-clipboard-list"></i>Apply Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    );
  }
}

export default HeaderTwo
