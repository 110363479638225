import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu.js";

function StickyMenu() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".sticky-menu");

      if (window.scrollY > 160) {
        stickyMenu.classList.add("sticky");
      } else {
        stickyMenu.classList.remove("sticky");
      }
    });
  });

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className="sticky-menu">
        <Container>
          <Row>
            <Col md="5">
              <div className="logo" style={{
                display: "flex",
                alignItems: "center",
              }}>
                <Link to={"/"}>
                  <img
                    src={"https://res.cloudinary.com/da5mimn3m/image/upload/v1712737763/mlc/Monalisa%20D.pharma%20College/WhatsApp_Image_2024-04-07_at_09.25.12_o4jz3m.jpg"}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Link>
                <Link to={"/"} style={{
                  marginLeft: "15px",
                  fontSize: "15px",
                }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}>
                    MONALISA INSTITUTE OF MEDICAL SCIENCE
                  </p>
                  <p>
                    Recognized By PCI Govt. of India
                  </p>
                  <p>
                    Affiliated to WBSCTVESD, GOVT. of West Bengal
                  </p>
                </Link>
              </div>
            </Col>
            <Col md="7">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item dropdown active">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Home
                      {/* <i className="las la-angle-down"></i> */}
                    </Link>
                    {/* <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/"}
                          >
                            Home Style 1
                          </Link>
                        </li>
                        <li className="nav-item active">
                          <Link
                            className="nav-link"
                            to={ "/home-two"}
                          >
                            Home Style 2
                          </Link>
                        </li>
                      </ul> */}
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Pages <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/about"}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/gallery"}
                        >
                          Gallery
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/login"}
                          >
                            Log In
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/registration"}
                          >
                            Registration
                          </Link>
                        </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/contact"}
                        >
                          Contact
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/faq"}
                        >
                          Faq
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/404"}
                          >
                            404
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/coming-soon"}
                          >
                            Coming Soon
                          </Link>
                        </li> */}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Courses <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      {/* <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/course-grid"}
                          >
                            Course Grid
                          </Link>
                        </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/course-list"}
                        >
                          Course List
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={ "/course-details"}
                          >
                            Course Details
                          </Link>
                        </li> */}
                    </ul>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"/"}
                      data-toggle="dropdown"
                    >
                      Event <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/events"}
                        >
                          Events
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
                <div className="apply-btn">
                  <Link to={"/registration"}>
                    <i className="las la-clipboard-list"></i>Apply Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu