import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";

function MobileMenu() {
    useEffect(() => {
        // Mobile Menu
        const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            hmBtn.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.toggle("visible");
                mdSidebarBody.classList.toggle("opened");
            });

            mdSidebarOverlay.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });

            mdSidebarExit.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                mdSidebarBody.classList.remove("opened");
            });
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    return (
      <Styles>
        {/* Mobile Menu */}
        <section className="mobile-menu-area">
          <Container>
            <Row>
              <Col md="0" sm="12">
                <div className="mb-topbar d-flex justify-content-between">
                  <div className="topbar-item">
                    <p>
                      <i className="las la-phone"></i>9804588881 / 9193741234
                    </p>
                    <p>
                      <i className="las la-envelope"></i>monalisacollegeofpharmacy@gmail.com
                    </p>
                  </div>
                  <div className="topbar-item">
                    <ul className="list-unstyled list-inline">
                    </ul>
                  </div>
                </div>
                <div className="mb-logo-area d-flex justify-content-between">
                  <div className="mb-logo-box d-flex">
                    <div className="hm-button">
                      <a
                        href={ "/"}
                        id="mb-sidebar-btn"
                        style={{
                          fontSize: "30px",
                        }}
                      >
                        <i className="las la-bars"></i>
                      </a>
                    </div>
                    <div className="mb-logo">
                      <Link to={ "/"} style={{
                        display : "flex",
                      }}>
                        <img
                          src={
                      "https://res.cloudinary.com/da5mimn3m/image/upload/v1712991636/mlc/Monalisa%20D.pharma%20College/WhatsApp_Image_2024-04-07_at_09.25.12_o4jz3m.jpg"
                          }
                          alt=""
                          style={{
                            width: "70px",
                            height: "70px",
                            marginTop: "10px",
                          }}
                        />
                    <div style={{}}>
                    <p style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                      color: "#fff",
                    }}>
                    Monalisa Institute of 
                    </p>
                    <p style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                      color: "#fff",
                    }}>
                   Medical Science
                    </p>
                    <p style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                      marginLeft: "10px",
                    }}>
                      Recognized By PCI Govt. of India
                    </p>
                    <p style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                      marginLeft: "10px",
                    }}>
                      Affiliated to WBSCTVESD, GOVT. of West Bengal
                    </p>
                    </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Mobile Menu Sidebar */}
        <section className="mb-sidebar" id="mb-sidebar-body">
          <div className="mb-sidebar-heading d-flex justify-content-between">
            <div>
              <h5>Menu</h5>
            </div>
            <div>
              <a href={ "/"} id="close-mb-sidebar">
                <i className="las la-times"></i>
              </a>
            </div>
          </div>
          <div className="mb-sidebar-menu">
            <div className="mb-menu-item">
              <button className="mb-menu-button active">
                <p>
                  Home <i className="las la-plus"></i>
                </p>
              </button>
              <div className="mb-menu-content show">
                {/* <ul className="list-unstyled">
                  <li>
                    <Link to={ "/"}>Home Style 1</Link>
                  </li>
                  <li>
                    <Link to={ "/home-two"}>
                      Home Style 2
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="mb-menu-item">
              <button className="mb-menu-button active">
                <p>
                  Pages <i className="las la-plus"></i>
                </p>
              </button>
              <div className="mb-menu-content show">
                <ul className="list-unstyled">
                  <li>
                    <Link to={ "/about"}>About Us</Link>
                  </li>
                  <li>
                    <Link to={ "/gallery"}>
                      Gallery
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={ "/login"}>Log In</Link>
                  </li>
                  <li>
                    <Link to={ "/registration"}>
                      Registration
                    </Link>
                  </li> */}
                  <li>
                    <Link to={ "/contact"}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to={ "/faq"}>Faq</Link>
                  </li>
                  {/* <li>
                    <Link to={ "/404"}>404</Link>
                  </li>
                  <li>
                    <Link to={ "/coming-soon"}>
                      Coming Soon
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="mb-menu-item">
              <button className="mb-menu-button active">
                <p>
                  Courses <i className="las la-plus"></i>
                </p>
              </button>
              <div className="mb-menu-content show">
                <ul className="list-unstyled">
                  {/* <li>
                    <Link to={ "/course-grid"}>
                      Course Grid
                    </Link>
                  </li> */}
                  <li>
                    <Link to={ "/course-list"}>
                      Course List
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={ "/course-details"}>
                      Course Details
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
           
            {/* <div className="mb-menu-item">
              <button className="mb-menu-button">
                <p>
                  Blog <i className="las la-plus"></i>
                </p>
              </button>
              <div className="mb-menu-content">
                <ul className="list-unstyled">
                  <li>
                    <Link to={ "/blog-classic"}>
                      Blog Classic
                    </Link>
                  </li>
                  <li>
                    <Link to={ "/blog-grid"}>
                      Blog Grid
                    </Link>
                  </li>
                  <li>
                    <Link to={ "/blog-details"}>
                      Blog Details
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-menu-item">
              <button className="mb-menu-button">
                <p>
                  Shop <i className="las la-plus"></i>
                </p>
              </button>
              <div className="mb-menu-content">
                <ul className="list-unstyled">
                  <li>
                    <Link to={ "/products"}>
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link to={ "/product-details"}>
                      Product Details
                    </Link>
                  </li>
                  <li>
                    <Link to={ "/cart"}>Cart</Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </section>
        <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
      </Styles>
    );
}

export default MobileMenu