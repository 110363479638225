import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import { Dropdown } from "react-bootstrap";
import Search from "./common/Search";
// import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/header.js";

class Header extends Component {
  render() {
    return (
      <Styles>
        {/* Topbar */}
        <section className="top-bar">
          <Container>
            <Row>
              <Col lg="6" md="5">
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <i className="las la-map-marker"></i>DUTTAPUKUR DIST - NORTH 24 PARGANAS, PIN -
                      743248.
                    </li>
                    <li className="list-inline-item">
                      <Link to={ "/faq"}>
                        Have Questions
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="6" md="7">
                <div className="bar-right d-flex justify-content-end">
                  {/* <ul className="list-unstyled list-inline bar-social">
                    <li className="list-inline-item">
                      <a href={ "/"}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={ "/"}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={ "/"}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={ "/"}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul> */}
                  {/* <ul className="list-unstyled list-inline bar-lang">
                    <li className="list-inline-item">
                      <Dropdown>
                        <Dropdown.Toggle as="a">
                          <img
                            src={
                               "/assets/images/us.png"
                            }
                            alt=""
                          />
                          English<i className="las la-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as="ul">
                          <Dropdown.Item as="li">
                            <img
                              src={
                                 "/assets/images/us.png"
                              }
                              alt=""
                            />{" "}
                            English
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <img
                              src={
                                
                                "/assets/images/fra.png"
                              }
                              alt=""
                            />{" "}
                            French
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <img
                              src={
                                
                                "/assets/images/ger.png"
                              }
                              alt=""
                            />{" "}
                            German
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <img
                              src={
                                
                                "/assets/images/spa.png"
                              }
                              alt=""
                            />{" "}
                            Spanish
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <img
                              src={
                                
                                "/assets/images/bra.png"
                              }
                              alt=""
                            />{" "}
                            Brazilian
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul> */}
                  {/* <ul className="list-unstyled list-inline bar-login">
                    <li className="list-inline-item">
                      <Link to={ "/login"}>
                        <i className="las la-user"></i>Log In
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to={ "/registration"}>
                        <i className="las la-user-edit"></i>Register
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Logo Area */}
        <section className="logo-area" style={{
          width: "90%",
          margin: "auto",
        }} >
            <Row>
              <Col md="4">
                <div className="logo">
                  <Link to={ "/"}>
                    <img
                      src={"https://res.cloudinary.com/da5mimn3m/image/upload/v1712737763/mlc/Monalisa%20D.pharma%20College/WhatsApp_Image_2024-04-07_at_09.25.12_o4jz3m.jpg"}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    <span style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "black",
                      marginLeft: "10px",
                    }}>
                    Monalisa B.Ed College
                    </span>
                  </Link>
                </div>
              </Col>
              <Col md="8">
                <div className="logo-contact-box d-flex justify-content-end">
                  <div className="emcontact-box d-flex">
                    <div className="box-icon">
                      <i className="flaticon-phone-call"></i>
                    </div>
                    <div className="box-content">
                      <p>Call Us Now</p>
                      <span>(+91) 9836922333</span>
                    </div>
                  </div>
                  <div className="emcontact-box d-flex">
                    <div className="box-icon">
                      <i className="flaticon-envelope"></i>
                    </div>
                    <div className="box-content">
                      <p>Enquery Us</p>
                      <span>monalisab.edcollege@gmail.com</span>
                    </div>
                  </div>
                  <div className="apply-btn">
                    <Link to={ "/registration"}>
                      <i className="las la-clipboard-list"></i>Apply Now
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
        </section>

        {/* Navbar */}
        <section className="main-menu">
          <Container>
            <Row>
              <Col md="12">
                <div className="main-menu-box">
                  <div className="menu-box d-flex justify-content-between">
                    <ul className="nav menu-nav">
                      <li className="nav-item dropdown active">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          HOME
                          {/* Home <i className="las la-angle-down"></i> */}
                        </Link>
                        {/* <ul className="dropdown list-unstyled">
                          <li className="nav-item active">
                            <Link
                              className="nav-link"
                              to={ "/"}
                            >
                              Home Style 1
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/home-two"}
                            >
                              Home Style 2
                            </Link>
                          </li>
                        </ul> */}
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          About us <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/about"}
                            >
                              About Monalisa
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/gallery"}
                            >
                              Gallery
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/login"}
                            >
                              Log In
                            </Link>
                          </li> */}
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/registration"}
                            >
                              Registration
                            </Link>
                          </li> */}
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/contact"}
                            >
                              Contact
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/faq"}
                            >
                              Faq
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/404"}
                            >
                              404
                            </Link>
                          </li> */}
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/coming-soon"}
                            >
                              Coming Soon
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Courses
                          <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/course-grid"}
                            >
                              Course Grid
                            </Link>
                          </li> */}
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/course-list"}
                            >
                              Course List
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/course-details"}
                            >
                              Course Details
                            </Link>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Blog <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/blog-classic"}
                            >
                              Blog Classic
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/blog-grid"}
                            >
                              Blog Grid
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/blog-details"}
                            >
                              Blog Details
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={ "/"}
                          data-toggle="dropdown"
                        >
                          Shop <i className="las la-angle-down"></i>
                        </Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/products"}
                            >
                              Products
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/product-details"}
                            >
                              Product Details
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={ "/cart"}
                            >
                              Cart
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                 
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    );
  }
}

export default Header;
